import dynamic from 'next/dynamic';
import { memo, MemoExoticComponent, ReactElement, ReactNode } from 'react';
import IconComment from '@icons/comment.svg';

const IconAdmin = dynamic(() => import('@icons/admin.svg'));
const IconArrowEnter = dynamic(() => import('@icons/arrow-enter.svg'));
const IconArrowsDown = dynamic(() => import('@icons/arrows-down.svg'));
const IconArrowsRight = dynamic(() => import('@icons/arrows-right.svg'));
const IconArrowSubmit = dynamic(() => import('@icons/arrow-submit.svg'));
const IconAssist = dynamic(() => import('@icons/assist.svg'));
const IconBanner = dynamic(() => import('@icons/banner.svg'));
const IconBasePlace = dynamic(() => import('@icons/base-place.svg'));
const IconBell = dynamic(() => import('@icons/bell.svg'));
const IconBlocks = dynamic(() => import('@icons/blocks.svg'));
const IconCalendar = dynamic(() => import('@icons/calendar.svg'));
const IconCalendarWithDays = dynamic(() => import('@icons/calendar-with-days.svg'));
const IconCancelledGoal = dynamic(() => import('@icons/cancelled-goal.svg'));
const IconCancelledOwnGoal = dynamic(() => import('@icons/cancelled-own-goal.svg'));
const IconCarrousel = dynamic(() => import('@icons/carrousel.svg'));
const IconCheckCircle = dynamic(() => import('@icons/check-circle.svg'));
const IconCommentEmpty = dynamic(() => import('@icons/comment-empty.svg'));
const IconDeleteTrash = dynamic(() => import('@icons/delete-trash.svg'));
const IconDossier = dynamic(() => import('@icons/dossier.svg'));
const IconDots = dynamic(() => import('@icons/dots.svg'));
const IconDown = dynamic(() => import('@icons/down.svg'));
const IconDownCenter = dynamic(() => import('@icons/down-center.svg'));
const IconDraw = dynamic(() => import('@icons/draw.svg'));
const IconEdit = dynamic(() => import('@icons/edit.svg'));
const IconEditor = dynamic(() => import('@icons/editor.svg'));
const IconField = dynamic(() => import('@icons/field.svg'));
const IconFilter = dynamic(() => import('@icons/filter.svg'));
const IconFlag = dynamic(() => import('@icons/flag.svg'));
const IconFootball = dynamic(() => import('@icons/football.svg'));
const IconFootballRed = dynamic(() => import('@icons/own-goal.svg'));
const IconGlobe = dynamic(() => import('@icons/globe.svg'));
const IconGraph = dynamic(() => import('@icons/graph.svg'));
const IconHide = dynamic(() => import('@icons/hide.svg'));
const IconHideUser = dynamic(() => import('@icons/hide-user.svg'));
const IconHome = dynamic(() => import('@icons/home.svg'));
const IconLeague = dynamic(() => import('@icons/league.svg'));
const IconLeft = dynamic(() => import('@icons/left.svg'));
const IconLeftCenter = dynamic(() => import('@icons/left-center.svg'));
const IconLike = dynamic(() => import('@icons/like.svg'));
const IconLikeFilled = dynamic(() => import('@icons/like-filled.svg'));
const IconListBig = dynamic(() => import('@icons/list-big.svg'));
const IconListSmall = dynamic(() => import('@icons/list-small.svg'));
const IconLoss = dynamic(() => import('@icons/loss.svg'));
const IconMatches = dynamic(() => import('@icons/matches.svg'));
const IconMedianBlock = dynamic(() => import('@icons/median-block.svg'));
const IconMenu = dynamic(() => import('@icons/menu.svg'));
const IconMinus = dynamic(() => import('@icons/minus.svg'));
const IconMinusBlock = dynamic(() => import('@icons/minus-block.svg'));
const IconMinusCenter = dynamic(() => import('@icons/minus-circle-center.svg'));
const IconMinusCircle = dynamic(() => import('@icons/minus-circle.svg'));
const IconModerator = dynamic(() => import('@icons/moderator.svg'));
const IconPenaltyDisapproved = dynamic(() => import('@icons/disapproved-penalty.svg'));
const IconPenaltyMissed = dynamic(() => import('@icons/penalty-missed.svg'));
const IconPenaltyScored = dynamic(() => import('@icons/penalty-scored.svg'));
const IconPlayedMatches = dynamic(() => import('@icons/played-matches.svg'));
const IconPlus = dynamic(() => import('@icons/plus.svg'));
const IconPlusBlock = dynamic(() => import('@icons/plus-block.svg'));
const IconPlusCenter = dynamic(() => import('@icons/plus-circle-center.svg'));
const IconPlusCircle = dynamic(() => import('@icons/plus-circle.svg'));
const IconPosition = dynamic(() => import('@icons/position.svg'));
const IconPro = dynamic(() => import('@icons/pro.svg'));
const IconProInverse = dynamic(() => import('@icons/pro-inverse.svg'));
const IconRead = dynamic(() => import('@icons/read.svg'));
const IconRedCard = dynamic(() => import('@icons/red-card.svg'));
const IconRedCardCancelled = dynamic(() => import('@icons/cancelled-red-card.svg'));
const IconRemoveComment = dynamic(() => import('@icons/comment-remove.svg'));
const IconRemoveFlag = dynamic(() => import('@icons/remove-flag.svg'));
const IconRight = dynamic(() => import('@icons/right.svg'));
const IconRightCenter = dynamic(() => import('@icons/right-center.svg'));
const IconSearch = dynamic(() => import('@icons/search.svg'));
const IconSecondYellowCard = dynamic(() => import('@icons/second-yellow-card.svg'));
const IconSecondYellowCardCancelled = dynamic(() => import('@icons/cancelled-second-yellow-card.svg'));
const IconSettings = dynamic(() => import('@icons/settings.svg'));
const IconShow = dynamic(() => import('@icons/show.svg'));
const IconSignOut = dynamic(() => import('@icons/sign-out.svg'));
const IconStar = dynamic(() => import('@icons/star.svg'));
const IconStopwatch = dynamic(() => import('@icons/stopwatch.svg'));
const IconSubstitution = dynamic(() => import('@icons/substitution.svg'));
const IconSwitchIn = dynamic(() => import('@icons/switch-in.svg'));
const IconSwitchOut = dynamic(() => import('@icons/switch-out.svg'));
const IconTime = dynamic(() => import('@icons/time.svg'));
const IconUndoRemoveComment = dynamic(() => import('@icons/comment-undo-remove.svg'));
const IconUp = dynamic(() => import('@icons/up.svg'));
const IconUpCenter = dynamic(() => import('@icons/up-center.svg'));
const IconUser = dynamic(() => import('@icons/user.svg'));
const IconUserCircle = dynamic(() => import('@icons/user-circle.svg'));
const IconVideo = dynamic(() => import('@icons/video.svg'));
const IconWin = dynamic(() => import('@icons/win.svg'));
const IconX = dynamic(() => import('@icons/x.svg'));
const IconXCircle = dynamic(() => import('@icons/x-circle.svg'));
const IconYellowCard = dynamic(() => import('@icons/yellow-card.svg'));
const IconYellowCardCancelled = dynamic(() => import('@icons/cancelled-yellow-card.svg'));

export enum Option {
    admin,
    arrowEnter,
    arrowsDown,
    arrowsRight,
    arrowSubmit,
    assist,
    banner,
    basePlace,
    bell,
    blocks,
    calendar,
    calendarWithDays,
    cancelledGoal,
    cancelledOwnGoal,
    carrousel,
    checkCircle,
    close,
    comment,
    commentEmpty,
    deleteTrash,
    dots,
    dossier,
    down,
    downCenter,
    draw,
    edit,
    editor,
    field,
    filter,
    football,
    footballRed,
    flag,
    globe,
    graph,
    hide,
    hideUser,
    home,
    iconVideo,
    league,
    left,
    leftCenter,
    like,
    likeFilled,
    listBig,
    listSmall,
    loss,
    matches,
    medianBlock,
    menu,
    minus,
    minusBlock,
    minusCircle,
    minusCenter,
    moderator,
    penaltyDisapproved,
    penaltyMissed,
    penaltyScored,
    playedMatches,
    plus,
    plusBlock,
    plusCircle,
    plusCenter,
    position,
    pro,
    proInverse,
    read,
    redCard,
    redCardCancelled,
    removeComment,
    removeFlag,
    right,
    rightCenter,
    search,
    secondYellowCard,
    secondYellowCardCancelled,
    settings,
    show,
    signOut,
    star,
    stopwatch,
    substitution,
    switchIn,
    switchOut,
    time,
    undoRemoveComment,
    up,
    upCenter,
    user,
    userCircle,
    win,
    x,
    xCircle,
    yellowCard,
    yellowCardCancelled,
}

const keepColorList = [
    Option.arrowEnter,
    Option.assist,
    Option.cancelledGoal,
    Option.cancelledOwnGoal,
    Option.down,
    Option.field,
    Option.footballRed,
    Option.loss,
    Option.penaltyDisapproved,
    Option.penaltyMissed,
    Option.penaltyScored,
    Option.pro,
    Option.proInverse,
    Option.redCard,
    Option.redCardCancelled,
    Option.secondYellowCard,
    Option.secondYellowCardCancelled,
    Option.settings,
    Option.substitution,
    Option.switchIn,
    Option.switchOut,
    Option.win,
    Option.yellowCard,
    Option.yellowCardCancelled,
];

const IconByOption = (option: Option): typeof IconComment | null => {
    switch (option) {
        case Option.admin:
            return IconAdmin;
        case Option.arrowEnter:
            return IconArrowEnter;
        case Option.arrowsDown:
            return IconArrowsDown;
        case Option.arrowsRight:
            return IconArrowsRight;
        case Option.arrowSubmit:
            return IconArrowSubmit;
        case Option.assist:
            return IconAssist;
        case Option.banner:
            return IconBanner;
        case Option.basePlace:
            return IconBasePlace;
        case Option.bell:
            return IconBell;
        case Option.blocks:
            return IconBlocks;
        case Option.calendar:
            return IconCalendar;
        case Option.calendarWithDays:
            return IconCalendarWithDays;
        case Option.cancelledGoal:
            return IconCancelledGoal;
        case Option.cancelledOwnGoal:
            return IconCancelledOwnGoal;
        case Option.carrousel:
            return IconCarrousel;
        case Option.checkCircle:
            return IconCheckCircle;
        case Option.close:
            return IconX;
        case Option.comment:
            return IconComment;
        case Option.commentEmpty:
            return IconCommentEmpty;
        case Option.deleteTrash:
            return IconDeleteTrash;
        case Option.dots:
            return IconDots;
        case Option.dossier:
            return IconDossier;
        case Option.down:
            return IconDown;
        case Option.downCenter:
            return IconDownCenter;
        case Option.draw:
            return IconDraw;
        case Option.edit:
            return IconEdit;
        case Option.editor:
            return IconEditor;
        case Option.field:
            return IconField;
        case Option.filter:
            return IconFilter;
        case Option.football:
            return IconFootball;
        case Option.footballRed:
            return IconFootballRed;
        case Option.flag:
            return IconFlag;
        case Option.globe:
            return IconGlobe;
        case Option.graph:
            return IconGraph;
        case Option.hide:
            return IconHide;
        case Option.hideUser:
            return IconHideUser;
        case Option.home:
            return IconHome;
        case Option.iconVideo:
            return IconVideo;
        case Option.league:
            return IconLeague;
        case Option.left:
            return IconLeft;
        case Option.leftCenter:
            return IconLeftCenter;
        case Option.like:
            return IconLike;
        case Option.likeFilled:
            return IconLikeFilled;
        case Option.listBig:
            return IconListBig;
        case Option.listSmall:
            return IconListSmall;
        case Option.loss:
            return IconLoss;
        case Option.matches:
            return IconMatches;
        case Option.medianBlock:
            return IconMedianBlock;
        case Option.menu:
            return IconMenu;
        case Option.minus:
            return IconMinus;
        case Option.minusBlock:
            return IconMinusBlock;
        case Option.minusCircle:
            return IconMinusCircle;
        case Option.minusCenter:
            return IconMinusCenter;
        case Option.moderator:
            return IconModerator;
        case Option.penaltyDisapproved:
            return IconPenaltyDisapproved;
        case Option.penaltyMissed:
            return IconPenaltyMissed;
        case Option.penaltyScored:
            return IconPenaltyScored;
        case Option.playedMatches:
            return IconPlayedMatches;
        case Option.plus:
            return IconPlus;
        case Option.plusBlock:
            return IconPlusBlock;
        case Option.plusCircle:
            return IconPlusCircle;
        case Option.plusCenter:
            return IconPlusCenter;
        case Option.position:
            return IconPosition;
        case Option.pro:
            return IconPro;
        case Option.proInverse:
            return IconProInverse;
        case Option.read:
            return IconRead;
        case Option.redCard:
            return IconRedCard;
        case Option.redCardCancelled:
            return IconRedCardCancelled;
        case Option.removeComment:
            return IconRemoveComment;
        case Option.removeFlag:
            return IconRemoveFlag;
        case Option.right:
            return IconRight;
        case Option.rightCenter:
            return IconRightCenter;
        case Option.search:
            return IconSearch;
        case Option.secondYellowCard:
            return IconSecondYellowCard;
        case Option.secondYellowCardCancelled:
            return IconSecondYellowCardCancelled;
        case Option.settings:
            return IconSettings;
        case Option.show:
            return IconShow;
        case Option.signOut:
            return IconSignOut;
        case Option.star:
            return IconStar;
        case Option.stopwatch:
            return IconStopwatch;
        case Option.substitution:
            return IconSubstitution;
        case Option.switchIn:
            return IconSwitchIn;
        case Option.switchOut:
            return IconSwitchOut;
        case Option.time:
            return IconTime;
        case Option.undoRemoveComment:
            return IconUndoRemoveComment;
        case Option.up:
            return IconUp;
        case Option.upCenter:
            return IconUpCenter;
        case Option.user:
            return IconUser;
        case Option.userCircle:
            return IconUserCircle;
        case Option.win:
            return IconWin;
        case Option.x:
            return IconX;
        case Option.xCircle:
            return IconXCircle;
        case Option.yellowCard:
            return IconYellowCard;
        case Option.yellowCardCancelled:
            return IconYellowCardCancelled;
        default:
            return null;
    }
};

interface Props {
    onClick?: () => void;
}

type Type = {
    [key: string]: MemoExoticComponent<(props: Props) => JSX.Element> | ((props: Props) => JSX.Element);
};

// FIXME: Dirty fix to make inherit-colors and memo work in storybook
declare global {
    interface Window {
        __STORYBOOK_PREVIEW__: any;
    }
}
const isStoryBook = typeof window === 'object' && window?.__STORYBOOK_PREVIEW__;

const IconElement = (type: Option) => {
    return (props: Props): ReactElement => {
        const classes = ['Icon', Option[type] + '-icon'];
        let inheritColor: ReactNode = null;

        if (!keepColorList.includes(type)) {
            classes.push('inherit-colors');

            // FIXME: Dirty fix to make inherit-colors work in storybook
            inheritColor = isStoryBook ? (
                <style jsx>{`
                    .Icon.inherit-colors svg > path,
                    .Icon.inherit-colors svg > g > path {
                        fill: currentColor;
                    }
                `}</style>
            ) : (
                <style jsx>{`
                    :global(.Icon.inherit-colors) :global(svg) > :global(path),
                    :global(.Icon.inherit-colors) :global(svg) > :global(g) > :global(path) {
                        fill: currentColor;
                    }
                `}</style>
            );
        }

        let _props = {
            ...props,
            className: classes.join(' '),
            style: {
                cursor: props.onClick ? 'pointer' : '',
            },
        };

        let _svgProps = {
            width: '100%',
            height: '100%',
            viewBox: '0 0 24 24',
            preserveAspectRatio: 'xMidYMid meet',
        };

        const ChosenIcon = IconByOption(type);
        if (ChosenIcon) {
            // FIXME: webpack couldn't properly import style from common folder
            return (
                <span {..._props}>
                    <style jsx>{`
                        .Icon {
                            display: inline-block;
                            vertical-align: top;
                            width: 1em;
                            height: 1em;
                        }
                    `}</style>
                    {inheritColor}
                    <ChosenIcon {..._svgProps} />
                </span>
            );
        } else {
            return <span {..._props}>Icon.{type} is not yet implemented</span>;
        }
    };
};

let icon: Type = {};
for (let key in Option) {
    if (isNaN(+key)) {
        const option: Option = Option[key as keyof typeof Option];
        icon[key] = isStoryBook ? IconElement(option) : memo(IconElement(option));
    } else {
        const option: Option = Option[Option[key] as keyof typeof Option];
        icon[Number(key)] = isStoryBook ? IconElement(option) : memo(IconElement(option));
    }
}

// Type IconKey for typing of the icon prop
export type IconKey = (typeof Option)[keyof typeof Option];
export const Icon = icon;
